import { createContext, useState } from "react";

export const DataContext = createContext(null);

const DataProvider = ({ children }) => {
  const initialValues = {
    year: "2023",
    round: "",
    instituteType: "",
    instituteCode: "",
    branchCode: "",
    seatType: "",
  };
  const [values, setValues] = useState(initialValues);
  const [displayValues, setDisplayValues] = useState(initialValues)

  return (
    <DataContext.Provider
      value={{
        values,
        setValues,
        displayValues,
        setDisplayValues
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
