export const years = {
  options: [
    { id: 1, year: "2016" },
    { id: 2, year: "2017" },
    { id: 3, year: "2018" },
    { id: 4, year: "2019" },
    { id: 5, year: "2020" },
    { id: 6, year: "2021" },
    { id: 7, year: "2022" },
    { id: 8, year: "2023" },
  ],
};


export const seatTypes = {
  options: [
    { id: 0, seatType: "All" },
    { id: 1, seatType: "OPEN" },
    { id: 2, seatType: "OBC-NCL" },
    { id: 3, seatType: "SC" },
    { id: 4, seatType: "ST" },
    { id: 5, seatType: "OPEN (PwD)" },
    { id: 6, seatType: "OBC-NCL (PwD)" },
    { id: 7, seatType: "SC (PwD)" },
    { id: 8, seatType: "ST (PwD)" },
    { id: 9, seatType: "EWS" },
    { id: 10, seatType: "EWS (PwD)" },
  ],
};

export const instituteTypes = {
  options: [
    { code: 'gov', instituteType: "Government Funded Technical Institutions" },
    { code: 'iit', instituteType: "Indian Institute of Technology" },
    { code: 'iiit', instituteType: "Indian Institute of Information Technology" },
    { code: 'nit', instituteType: "National Institute of Technology" },
  ],
};


