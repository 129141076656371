import React from "react";

const ResultComponent = ({ data }) => {
  console.log(data);

  return (
    <div className="bg-slate-200 w-80 sm:w-auto overflow-x-auto p-2 rounded-md container mx-auto">
      {data?.length > 0 ? (
        <div className=" overflow-x-auto max-w-full">
          <table className="table-auto rounded-md border-collapse border border-gray-600">
            <thead>
              <tr>
                <th className="px-4 py-2 border border-gray-600">Institute</th>
                <th className="px-4 py-2 sm:w-80 border border-gray-600">Academic Program Name</th>
                <th className="px-4 py-2 border border-gray-600">Quota</th>
                <th className="px-4 py-2 border border-gray-600">Seat Type</th>
                <th className="px-4 py-2 border border-gray-600">Gender</th>
                <th className="px-4 py-2 border border-gray-600">Opening Rank</th>
                <th className="px-4 py-2 border border-gray-600">Closing Rank</th>
                <th className="px-4 py-2 border border-gray-600">Round</th>
                <th className="px-4 py-2 border border-gray-600">Year</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item._id} className="border border-gray-600">
                  <td className="border border-gray-600 px-4 py-2">{item.institute}</td>
                  <td className="border border-gray-600 px-4 py-2 sm:w-36">{item.branch}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.quota}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.seatType}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.gender}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.or}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.cr}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.round}</td>
                  <td className="border border-gray-600 px-4 py-2">{item.year}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center items-center px-4 font-bold h-56 text-gray-400 text-6xl md:px-80">
          Sorry, No data found!
        </div>
      )}
    </div>
  );
};

export default ResultComponent;
