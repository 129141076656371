import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { DataContext } from "../context/DataProvider";
import SelectYear from "./select/SelectYear";
import SelectRound from "./select/SelectRound";
import SelectAcademicProgram from "./select/SelectAcademicProgram";
import SelectInstitute from "./select/SelectInstitute";
import SelectSeatType from "./select/SelectSeatType";
import SelectInstituteType from "./select/SelectInstiType";
import { years, seatTypes, instituteTypes } from "../assets/Data";

const SelectComponent = () => {
  const { values } = useContext(DataContext);

  const [round, setRound] = useState([]);
  //const [instituteType, setInstituteType] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [branch, setBranch] = useState([]);

  const getRounds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getOptions`,
        {
          params: { year: values.year },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success === true) {
        const data = response.data.data[0];

        if (data.round) setRound(data.round);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
  const getInstitute = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getOptions`,
        {
          params: {
            year: values.year,
            round: values.round,
            instituteType: values.instituteType,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success === true) {
        setBranch([])
        const data = response.data.data[0];

        if (data.institute) setInstitute(data.institute);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getBranch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getOptions`,
        {
          params: {
            year: values.year,
            round: values.round,
            instituteType: values.instituteType,
            instituteCode: values.instituteCode,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success === true) {
        const data = response.data.data[0];

        if (data.branch) setBranch(data.branch);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (values.year && values.round && values.instituteType && values.instituteCode) {
      getBranch();
    }
  }, [values.instituteCode]);
  
  useEffect(() => {
    if (values.year && values.round && values.instituteType) {
      getInstitute();
    }
  }, [values.instituteType]);
  
  /* useEffect(() => {
    if (values.year && values.round) {
      getInstituteType();
    }
  }, [values.round]); */
  
  useEffect(() => {
    if (values.year) {
      getRounds();
    }
  }, [values.year]);
  

  return (
    <div className="flex justify-center items-center rounded-lg bg-white p-4 -z-0">
      <div className="">
        <div className="">
          <div className="flex flex-col lg:flex-row rounded-lg border-2 border-gray-300 p-3 bg-white ">
            <div className="h-32 w-44 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-300 p-2 z-50">
              <SelectYear data={years} />
            </div>
            <div className="h-32 w-44 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-300 p-2 z-40">
              <SelectRound data={round} />
            </div>
            <div className="h-32 w-44 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-300 p-2 z-30">
              <SelectInstituteType data={instituteTypes} />
            </div>
            <div className="h-32 w-44 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-300 p-2 z-20">
              <SelectInstitute data={institute} />
            </div>
            <div className="h-32 w-44 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-300 p-2 z-10">
              <SelectAcademicProgram data={branch} />
            </div>

            <div className=" p-2">
              <SelectSeatType data={seatTypes} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectComponent;
