import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import LandingPage from "./pages/LandingPage";
import Result from "./pages/Result";
import ReactGA from "react-ga4";
import DataProvider from "./context/DataProvider";
import { useEffect } from "react";

ReactGA.initialize('G-Q19PYEWREY')

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    
    <DataProvider>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/result" element={<Result />}></Route>
      </Routes>
      </BrowserRouter>

    </DataProvider>
  );
}

export default App;
