import React from "react";
import logo from "../assets/logofooter.png";

const Footer = () => {
  return (
    <footer className="bg-gray-100 dark:bg-gray-800">
      <div className="mx-auto max-w-5xl px-4 flex-auto py-2 sm:px-6 lg:px-8">
        <div className="flex justify-center text-teal-600 dark:text-teal-300">
        <img className="h-10 w-auto" src={logo} alt="Exam India" />
        </div>

       
        <div className="w-full border-b-2 mt-4 opacity-10 bg-indigo-200"></div>
        <div className="mt-2 text-gray-600 flex justify-center items-center dark:text-gray-400 text-sm">
          &copy; 2024 Exams India. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
