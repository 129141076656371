import React, { useContext, useState, useMemo, useCallback, useEffect } from "react";
import Header from "../components/Header";
import SelectComponent from "../components/SelectComponent";
import { DataContext } from "../context/DataProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";

const LandingPage = () => {
  const navigate = useNavigate();
  const { values } = useContext(DataContext);
  const [isLoading, setLoading] = useState(false);

  const url = useMemo(() => process.env.REACT_APP_API_URL, []);

  const data = useMemo(
    () => ({
      year: values.year,
      round: values.round,
      instituteCode: values.instituteCode,
      branchCode: values.branchCode,
      seatType: values.seatType,
    }),
    [values]
  );

  const handleSubmit = useCallback(async () => {

    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Submit',
      label: 'ORCR',
    });

    try {
      setLoading(true);
      const filteredPayload = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== "")
      );

      const response = await axios.get(`${url}/getOrcr`, {
        params: filteredPayload,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setLoading(false);
        navigate("/result", { state: response.data.data });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  }, [data, navigate, url]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Landing Page" });
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col bg-gradient-to-b from-indigo-100 to-indigo-300">
        <Header />
        <div className="flex flex-col justify-center mt-4 px-6 lg:px-8 lg:mt-40 ">
          <div className="flex flex-col justify-center items-center mx-auto max-w-4xl">
            <h1 className="text-3xl font-semibold mb-8 sm:text-5xl">Welcome To Exam India</h1>
            <SelectComponent />
            <button
              type="button"
              onClick={handleSubmit}
              className="text-white mt-6 mb-4 bg-indigo-500 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5"
            >
              {isLoading ? (
                <div className="loader">
                  <ThreeDots visible={true} height={20} width={50} color="white" radius={6} ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
