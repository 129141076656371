import React, { useState, useContext, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import ReactGA from "react-ga4";
import ResultComponent from "../components/ResultComponent";
import Header from "../components/Header";
import SelectComponent from "../components/SelectComponent";
import Footer from "../components/Footer";
import { DataContext } from "../context/DataProvider";

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const { values } = useContext(DataContext);
  const { state } = location;

  const url = useMemo(() => process.env.REACT_APP_API_URL, []);
  
  const data = useMemo(
    () => ({
      year: values.year,
      round: values.round,
      instituteCode: values.instituteCode,
      branchCode: values.branchCode,
      seatType: values.seatType,
    }),
    [values]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const filteredPayload = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== "")
      );
      const response = await axios.get(`${url}/getOrcr`, {
        params: filteredPayload,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        navigate("/result", { state: response.data.data });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  }, [data, navigate, url]);

  useMemo(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Result Page" });
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col justify-center px-4 lg:px-8 flex-grow bg-gradient-to-b from-indigo-100 to-indigo-300">
        <div className="flex flex-col justify-center md:flex-row items-center mx-auto max-w-4xl my-8 lg:mt-12">
          <SelectComponent />
          <div className="p-2 flex items-center justify-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="text-white mt-6 mb-4 bg-indigo-500 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5"
            >
              {isLoading ? (
                <div className="loader">
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="50"
                    color="white"
                    radius="6"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-center overflow-x-auto items-center mx-auto mb-8 my-4 ">
          <ResultComponent data={state} />
        </div>
      </div>
      <Footer className="mt-4" />
    </div>
  );
};

export default Result;
