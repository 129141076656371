import { Fragment, useState, useContext } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { DataContext } from '../../context/DataProvider'

export default function SelectSeatType({data}) {
  const [selected, setSelected] = useState(data.options[0])
  const [query, setQuery] = useState('')
  const { setValues, values, displayValues, setDisplayValues } = useContext(DataContext);

  const handleSelection = (selectedOption) => {
    console.log(selectedOption);
    setValues({ ...values, seatType: selectedOption.seatType === 'All' ? '': selectedOption.seatType }); // Assuming you want to set the year as value
    setDisplayValues({...displayValues, seatType: selectedOption.seatType});
    setSelected(selectedOption);
  };
  const filteredData =
    query === ''
      ? data.options
      : data.options.filter((seat) =>
          seat.seatType
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="bg-white flex flex-col justify-center items-left h-24 top-16 w-40">
      <span className='font-mono text-sm pl-2 pb-1'>Seat Type/ Category</span>
      <Combobox value={selected} onChange={(selectedOption) => {
          handleSelection(selectedOption);
        }}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-300 sm:text-sm">
            <Combobox.Input
              className="w-full border  bg-indigo-100 rounded-md py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-1"
              displayValue={() => displayValues.seatType === "" ? "":displayValues.seatType}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Select or type"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-indigo-900"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute scrollbar-thin scrollbar-webkit mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredData.length === 0 && query !== '' ? (
                <div className="relative text-sm font-semibold cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((options) => (
                  <Combobox.Option
                    key={options.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-indigo-400 text-white text-sm font-semibold rounded-md' : 'text-gray-900 font-semibold rounded-md'
                      }`
                    }
                    value={options}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block overflow-hidden whitespace-nowrap text-ellipsis transition-all duration-300 hover:whitespace-normal hover:overflow-visible hover:text-no-truncate ${
                            selected ? 'text-sm font-semibold' : 'font-normal'
                          }`}
                        >
                          {options.seatType}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-indigo-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
